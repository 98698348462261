<template>
    <!-- 小程序手动发布 -->
    <div class="release">
        <div class="el-content" v-if="step==1">
            <div class="release-title">微信小程序</div>
            <a-row :gutter="16">
                <a-col :span="10">
                    <div class="r-con r-con-left">
                        <div class="rl-info">
                            <div class="rl-title">
                                <i class="ri-computer-line ri-top"></i>
                                <span>当前版本</span>
                            </div>
                            <p class="rl-desc">如需更新当前版本，请去小程序后台发送推送版本</p>
                            <a-button type="primary" @click="checkWxMiniNewVersion">检测小程序版本</a-button>
                        </div>
                        <div class="rl-qrcode">
                            <img class="rl-qrcode-img" alt="" :src="current_preview_qrcode">
                            <div class="rl-qrcode-desc">当前版本入口</div>
                        </div>
                    </div>
                </a-col>
                <a-col :span="14">
                    <div class="r-con r-con-right" v-if="upload">
                        <div class="rr-title">
                            <i class="ri-send-plane-fill ri-top"></i>推送版本{{upload.version}}
                        </div>
                        <div class="rr-desc">
                            <span>版本描述：</span>
                            {{ upload.version_desc}}
                        </div>
                        <div class="rr-btm">
                            <a-checkbox class="mb12" v-model:checked="upload.is_plugins" >
                                <a-tooltip title="请确认您的小程序已经开通了直播插件,否则小程序无法正常使用">
                                    <span>直播插件</span>
                                </a-tooltip>
                            </a-checkbox>
                            <br>
                            <a-button type="primary" v-has="{action:'wxapp_set_create_publish'}" @click="createPushVersion">创建推送版本</a-button>
                        </div>
                    </div>
                </a-col>
            </a-row>

            <div class="release-title" style="margin-top:24px;">数据初始化</div>
            <a-space>
                <a-button @click="initPlatformTypeData"  v-has="{action:'wxapp_set_publish_initpro_type'}">初始化商品、土地分类数据</a-button>
                <a-button @click="initPlatformData"  v-has="{action:'wxapp_set_publish_initpro'}">初始化商品、认养、土地数据</a-button>
            </a-space>
        </div>

        <div v-if="step != 1">
            <div class="el-content mb12" style="padding:24px 48px;">
                <a-steps :current="upload.active">
                    <a-step title="扫码上传" description="公众平台账号授权绑定"></a-step>
                    <a-step title="填写版本信息" description="填写小程序版本信息，上传代码"></a-step>
                    <a-step title="提交微信审核" description="提交代码,等待微信审核"></a-step>
                </a-steps>
            </div>

            <div class="el-content" v-if="step == 2">
                <!-- 扫码上传 -->
				<div class="rc-upload">
					<div class="rcu-title">扫码上传</div>
					<div class="rcu-tags">公众平台账号授权绑定</div>
					<div class="rcu-qrcode" v-if="upload.login_qrcode">
						<img :style="{opacity:upload.logining?  0.2 : 1}" class="rcu-qrcode-border" :src="upload.login_qrcode" alt="">
						<a-button v-if="upload.logining" class="rcu-qrcode-login-txt" type="link">正在登陆</a-button>
					</div>
					<div class="rcu-qrcode" v-else>
						<img class="rcu-qrcode-border" src="@/assets/img/qrcode-border.png" alt="">
						<i class="ri-mini-program-fill"></i>
					</div>
					<div class="rc-upload-tag1" v-if="upload.login_qrcode">请用微信扫描上方二维码进行绑定授权</div>
					<a-button v-else style="width: 250px" type="primary" @click="getAuthQrcode">获取授权二维码</a-button>
				</div>
            </div>

            <div class="el-content" v-if="step == 3">
                <div class="rc-upload">
					<div class="rcu-title">上传代码</div>
					<a-button style="width: 250px;margin-top: 50px;" type="primary" @click="uploadCode">上传代码</a-button>
				</div>
            </div>

            <div v-if="step==4 || step == 5">
                <div class="el-content">
                    <div class="rc-upload">
                        <div style="margin-top: 50px;"></div>
                        <i class="ri-upload-cloud-2-fill"></i>
                        <div class="rcu-tags">{{step == 4 ?'上传中...' :'上传成功'}}</div>
                        <a-button v-if="step==4" style="width: 250px;margin-top: 50px;" type="primary" disabled loading>正在上传...</a-button>
                        <div class="rcu-tags-mini" v-if="step == 4">提示：需要等待 2-6分钟左右处理完毕</div>
                        <a-button v-if="step==5" style="width: 250px;margin-top: 50px;" type="primary" @click="getPreview">预览</a-button>
                        
                        <!-- 上传成功引导用户去小程序平台提交审核 -->
                        <div class="rc-upload-success-tip">
                            <div class="rcust-til">上传成功后，请登录微信小程序平台（<a href="https://mp.weixin.qq.com" target="_blank">https://mp.weixin.qq.com</a>）发布小程序</div>
                            <div class="rcust-til">版本号：{{upload.version}}</div>
                            <div class="rcust-til">版本描述：{{upload.version_desc}}</div>
                            <img :src="$util.getStaticSrc('/admin/common/wxapp-upload-success.png')" alt="">
                        </div>
                    </div>
                </div>
            </div>

            <div class="el-content" v-if="step == 6 ">
                <div class="rc-upload">
                    <div class="rcu-title">预览小程序</div>
                    <div class="rcu-qrcode" v-if="upload.preview_qrcode">
                        <img class="rcu-qrcode-border" :src="upload.preview_qrcode" alt="">
                    </div>
                    
                    <div class="rcu-qrcode" v-else>
                        <img class="rcu-qrcode-border" src="@/assets/img/qrcode-border.png" alt="">
                        <i class="ri-mini-program-fill"></i>
                    </div>
                    <a-button style="width: 250px" type="primary" @click="step = 1">返回</a-button>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import releaseModel from '@/api/wxmini-release.js'
import goodsModel from '@/api/goods.js'
import landModel from '@/api/land.js'
import adoptModel from '@/api/adopt.js'
import tool from '@/util/tool.js'
import { reactive, toRefs } from 'vue'
export default {
    name:'page-release',
    setup() {
        const _d = reactive({
            step:1,
            current_preview_qrcode:"",      //当前版本入口
            upload:{
                active:1,
                version:"",
                version_desc:"",
                is_plugins:false,       //直播插件
                login_qrcode:"",        //授权登录二维码
                logining:false,
                uuid:"",
                push_preview_qrcode:"",
            }
        })

        releaseModel.getMiniappPreviewQrcode(res=>{
            _d.current_preview_qrcode = res
        })
        releaseModel.getPushVersion(res=>{
            _d.upload.version = res.version
            _d.upload.version_desc = res.desc
            _d.upload.push_preview_qrcode = res.qr_code
        })
        function checkWxMiniNewVersion(){
            releaseModel.checkMiniappVersion()
        }

        function createPushVersion(){
            releaseModel.createPushVersion(_d.upload.version,{is_live:_d.upload.is_plugins},res=>{
                _d.step = 2
                _d.upload.uuid = res.uuid
                _d.upload.login_qrcode = ''
                _d.upload.preview_qrcode = ''
            })
        }

        function getAuthQrcode(){
            releaseModel.getAuthLoginQrcode(_d.upload.uuid,res=>{
                _d.upload.login_qrcode = res.qrcode
                checkAuthLogin()
            })
        }

        function checkAuthLogin(){
          let timer = setInterval(function(){
                releaseModel.checkLogin(_d.upload.uuid,res=>{
                    if( res.status == 0 ){
                        _d.upload.logining = false
                        _d.upload.active = 2
                        _d.step = 3
                        clearInterval(timer)
                    }
                    if(res.status == 2){
                        _d.step = 2
                    }
                })
            },2000)
        }

        function uploadCode(){
            let _u = _d.upload
            _d.step = 4
            releaseModel.uploadCode(_u.uuid,_u.version,_u.version_desc,_u.is_plugins ? 1:0,res=>{
                console.log('res',res);
                
                if(res.code == 0 ){
                    _d.step = 5
                    _d.upload.active = 2
                }else{
                    _d.step = 3
                }
            })
        }

        function getPreview() {
            releaseModel.getPreview(_d.upload.uuid,res=>{
                _d.step = 6
                _d.upload.preview_qrcode = res.qrcode
            })
        }

        function initPlatformTypeData(){
            goodsModel.getCategroyList(1,1,null,res=>{
                if(res.length == 0 ){
                    let form={
                        name:'蔬菜',
                        icon:'https://kundian.cqkundian.com/farm/1/png/20200314/6524d42e9f03cfc732bd16e7477ea1831584153556',
                        type:1,
                        rank:99,
                        is_show:1,
                    }
                    goodsModel.addOrEditCategory(form,()=>{
                        tool.message("商品分类数据初始化成功")
                    })
                    return;
                }
                tool.message("已存在商品分类！","warning")
            })
       
            landModel.getLandCategroy(1,1,null,res=>{
                if(res.list.length > 0){
                    tool.message("已存在土地分类","warning")
                    return;
                }

                let form = {
                    name:'A区地块',
                    rank:99,
                }
                landModel.addOrEditCategory(form,()=>{
                    tool.message("土地分类数据初始化成功")
                })
            })
       
            adoptModel.getAdoptCategroy(1,1,null,res=>{
                if(res.list.length > 0){
                    tool.message("已存在认养分类","warning")
                    return;
                }

                let form = {
                    name:'A区地块',
                    rank:99,
                    status:1
                }
                adoptModel.addOrEditCategory(form,()=>{
                    tool.message("认养分类数据初始化成功")
                })
            })
       }
        
        function initPlatformData(){
            goodsModel.getCategroyList(1,1,null,res=>{
                if(res.length == 0 ){
                    tool.message("请初始化商品分类后，再初始化商品信息！","warning")
                    return;
                }
                goodsModel.getGoodsList(1,1,null,goods=>{
                    if(goods.list.length == 0 ){
                        let form={
                            rank:99,
                            price:'0.01',
                            cover:'https://kundian.cqkundian.com/farm/1/png/20200413/e24bebe722ed3afdcf4924e70d6cc2bd1586745431',
                            score:0,
                            count:100,
                            weight:1000,
                            outlet:[],
                            send_type:1,
                            goods_type:1,
                            freight_id:0,
                            sale_count:0,
                            old_price:'',
                            goods_name:'现采现发 新鲜青菜 蔬菜类叶菜有机蔬菜',
                            goods_desc:'现采现发 新鲜青菜 蔬菜类叶菜有机蔬菜',
                            send_price:'',
                            is_assemble:0,
                            service_id:[],
                            goods_slide:[
                                'https://kundian.cqkundian.com/farm/1/png/20200413/e24bebe722ed3afdcf4924e70d6cc2bd1586745431'
                            ],
                            category_id:res.data[0].id,
                            wholesale_price:'',
                            is_purchase:0,
                            is_open_sku : 0,
                            goods_remark:'',
                            is_recommend :1,
                            is_put_away : 1,
                            assemble_price:0,
                            assemble_people:0,
                            goods_video_src:'',
                            is_user_discount:0,
                            quota_free_shipping:0,
                            piece_free_shipping:0,
                            is_distribution:0,
                            one_reward_unit:0,
                            one_unit_num:'',
                            two_reward_unit:0,
                            two_unit_num:'',
                            latitude:'29.612734',
                            longitude:'106.29989',
                        }
                        goodsModel.addOrEditGoods(form,[],[],res=>{
                            tool.message("商品数据初始化成功","warning")
                        })
                        return
                    }
                    tool.message("已存在商品信息，不需要再进行初始化","warning")
                })
            })
        
            landModel.getLandCategroy(1,1,null,res=>{
                if(res.list.length == 0){
                    tool.message("请初始化商品分类后，再初始化商品信息","warning")
                    return;
                }

                landModel.getLandList(1,1,null,res=>{
                    if(res.list.length >0 ){
                        tool.message("已存在土地信息，不需要再进行初始化","warning")
                        return
                    }

                    let form = {
                        did:'',
                        rank: 99,
                        score:'',
                        cover: 'http://farmkd.oss-cn-beijing.aliyuncs.com/farm/1/jpg/20191205/8c6e04bcebb9da44db745a159fcf089b1575523463.jpg',
                        live_id: 0,
                        land_name: 'A区地块',
                        sow_status: 0,
                        land_desc: 'A区地块',
                        land_intro: 'A区地块',
                        is_put_away:1,
                        category_id: landTypeRes.data[0].id,
                        delivery_time: 1,
                        is_user_discount: 0,
                        is_distribution:0,
                        one_reward_unit:0,
                        one_unit_num:'',
                        two_reward_unit:0,
                        two_unit_num:'',
                        latitude:'29.612734',
                        longitude:'106.29989'
                    }
                    let landSpec = [{
                        did:'',
                        area: 10,
                        cost:1,
                        alias: '土地',
                        live_id: 0,
                        device_id: 0,
                        land_num: '#1号',
                    }]
                    let landDeliverCycle = [{
                        day: 5,
                        rank: 99,
                        alias: '一周一次',
                    }]

                    landModel.addOrEditLand(form,landSpec,landDeliverCycle,()=>{
                        tool.message("土地数据初始化成功","warning")
                    })
                })
            })
       
            adoptModel.getAdoptCategroy(1,1,null,res=>{
                if(res.list.length == 0){
                    tool.message("请初始化认养分类后，再初始化商品信息！","warning")
                    return;
                }
                adoptModel.getAdoptList(1,1,null,res=>{
                    if(res.list.length > 0 ){
                        tool.message("已存在商品信息，不需要再进行初始化","warning")
                        return
                    }

                    let form = {
                        unit:'只',
                        count:10,
                        rank:99,
                        name:'土鸡认养',
                        cover:'https://kundian.cqkundian.com/farm/1/png/20200324/dd667fbd879ab97e6ffe0947dab41eb81585037458',
                        price:0.01,
                        score:'',
                        slide:[
                            'https://kundian.cqkundian.com/farm/1/png/20200324/dd667fbd879ab97e6ffe0947dab41eb81585037458'
                        ],
                        rights:[],
                        limit_buy:0,
                        buy_count:0,
                        is_discount:0,
                        is_put_away:1,
                        is_recommend:0,
                        description:'',
                        delivery_type:1,
                        limit_purchase:'',
                        kind_description:'',
                        price_description:'',
                        simple_description:'',
                        is_distribution:0,
                        one_reward_unit:0,
                        one_unit_num:'',
                        two_reward_unit:0,
                        two_unit_num:'',
                        latitude:'29.612734',
                        longitude:'106.29989',
                        purchase_month:"1"
                    }
                    let deliverCycle = [{
                        day: 5,
                        rank: 99,
                        alias: '一周一次',
                        min_times:'',
                    }]
                    adoptModel.addOrEditAdopt(form,deliverCycle,()=>{
                        tool.message("认养数据初始化成功","warning")
                    })
                })
               
            })
       }


        return{
            ...toRefs(_d),
            getAuthQrcode,
            createPushVersion,
            uploadCode,
            getPreview,
            initPlatformTypeData,
            initPlatformData,
            checkWxMiniNewVersion
        }
    },
}
</script>
<style lang="scss" scoped>
.r-con{
    border: 1px solid #f2f1f1;
    height: 240px;
}
.release-title{
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 12px;
}
.r-con-left{
    display: flex;

    .rl-info{
        padding: 48px 24px;
        box-sizing: border-box;
        width: 50%;
    }
    .rl-title{
        font-size: 20px;
        font-weight: bold;
        color: #00CC66;
        margin-bottom: 24px;
    }
    .rl-qrcode{
        width: 50%;
        text-align: center;
        padding: 24px 0;
        &-img{
            width: 160px;
            height: 160px;
        }

        &-desc{
            font-size: 14px;
            color: #00CC66;
            margin-top: 6px;
            font-weight: bold;
        }
    }
}

.r-con-right{
    padding: 24px;

    .rr-title{
        font-weight: bold;
        font-size: 18px;
        color: #FF6600;
    }
    .rr-desc{
        margin-top: 12px;
        font-size: 13px;
        span{
            color: #999;
            margin-left: 6px;
        }
    }
    .rr-btm{
        margin-top: 32px;
        margin-left: 6px;
    }
}

// 扫码上传
.rc-upload{
    width: 100%;
    text-align: center;
    min-height: 440px;
    
    .rcu-title{
        font-size: 16px;
        font-weight: 700;
        margin-top: 50px;
    }
    .rcu-tags{
        font-size: 14px;
        color: #999;
        margin-top: 10px;
    }
    .rcu-tags-mini{
        font-size: 14px;
        color: #999;
        margin-top: 16px;
    }
    .rcu-qrcode{
        width: 300px;
        margin: auto;
        height: 300px;
        text-align: center;
        position: relative;
        margin-top: 20px;
        
        &-border{
            width: 250px;
            height: 250px;
        }
        .ri-mini-program-fill{
            font-size: 120px;
            color: #67c23a;
            position: absolute;
            top: 11%;
            left: 29%;
        }
        
        .rcu-qrcode-login-txt{
            position: absolute;
            left: 40%;
            top: 38%;
        }
        
    }
    
    &-tag1{
        color: #999;
        font-size: 15px;
    }
    
    .rcu-contanier{
        width: 40%;
        margin: auto;
        margin-top: 20px;
    }
    
    .ri-upload-cloud-2-fill{
        font-size: 50px;
        color: #82d239;
    }
    
    &-success-tip{
        width: 100%;
        
        .rcust-til{
            margin: 12px 0;
            color: #666;
            font-size: 12px;
        }
    }
    
}

</style>