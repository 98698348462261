<template>
    <div class="el-content">
        <!-- 微信小程序相关配置 -->
        <a-tabs defaultActiveKey>
            <a-tab-pane v-for="item in tabs" :key="item.key" :tab="item.tab">
                <div v-if="item.key == 1">
                    <a-card title="第三方授权信息" class="mb12" v-if="wx_mini_use_open == 1">
                        <a-form-item label="第三方授权" v-if="!account">
                            <a-button type="primary" @click="getAuthUrl">获取授权</a-button>
                        </a-form-item>
                        <a-form-item label="授权信息" v-if="account">
                            <div class="sw-account-card">
                                <img :src="account.account.authorizer_info.head_img" alt="">
                                <div class="sw-account-card-right">
                                    <div class="sw-acr-name">{{account.account.authorizer_info.nick_name}}</div>
                                    <div>主体名称：{{account.account.authorizer_info.principal_name}}</div>
                                    <div>小程序APPID：{{account.app_id}}</div>
                                    <div>小程序原始ID：{{account.account.authorizer_info.user_name}}</div>
                                    <a-button class="sw-acr-btn" type="primary" @click="getAuthUrl">重新授权</a-button>
                                </div>
                            </div>
                        </a-form-item>
                    </a-card>
                    <a-card class="mb12">
                        <template #title>
                            <span>小程序服务器域名配置</span>
                            <a href="https://www.yuque.com/kundian/farm/yn70x6" target="_blank" style="font-size: 12px;">
                                如何配置小程序域名？
                            </a>
                        </template>
                        <a-form :label-col="{span:3}" :wrapper-col="{span:12}">
                            <a-form-item label="request 合法域名">
                                <a-input v-model:value="saasWxppSet.wx_mini_request_domain" disabled>
                                    <template #addonAfter>
                                        <com-copy :value="saasWxppSet.wx_mini_request_domain" :custom="true">复制</com-copy>
                                    </template>
                                </a-input>
                            </a-form-item>
                            <a-form-item label="uploadFile 合法域名">
                                <a-input v-model:value="saasWxppSet.wx_mini_uploadFile_domain" disabled>
                                    <template #addonAfter>
                                        <com-copy :value="saasWxppSet.wx_mini_uploadFile_domain" :custom="true">复制</com-copy>
                                    </template>
                                </a-input>
                            </a-form-item>
                            <a-form-item label="downloadFile 合法域名">
                                <a-input v-model:value="saasWxppSet.wx_mini_downloadFile_domain" disabled>
                                    <template #addonAfter>
                                        <com-copy :value="saasWxppSet.wx_mini_downloadFile_domain" :custom="true">复制</com-copy>
                                    </template>
                                </a-input>
                            </a-form-item>
                            <a-form-item label="业务域名">
                                <a-input v-model:value="saasWxppSet.domain_apply" disabled>
                                    <template #addonAfter>
                                        <com-copy :value="saasWxppSet.domain_apply" :custom="true">复制</com-copy>
                                    </template>
                                </a-input>
                            </a-form-item>
                        </a-form>
                    </a-card>

                    <com-power-form form-type="card" :form-data="[
                        {title:'微信小程序',param:[
                            {label:'身份标识(appId)',type:'text',value:'',key:'wx_mini_appid',dataType:'string'},
                            {label:'身份密钥(appSecret)',type:'hide-text',value:'',key:'wx_mini_appsecert',dataType:'string'},
                            {label:'原始ID',type:'text',value:'',key:'wx_mini_originid',dataType:'string',
                                tips:'设置小程序原始id,在APP端打开直播页面时,可直接跳转小程序观看!'
                            },
                        ]},
                        {title:'微信小程序（服务商）',param:[
                            {label:'商户证书序列号',type:'text',value:'',key:'wx_mini_mch_cert_num',dataType:'string'},
                            {label:'V3API秘钥',type:'text',value:'',key:'wx_mini_v3vip_key',dataType:'string'},
                            {label:'商户私钥',type:'upload-cert',value:'',key:'wx_mini_mch_key',dataType:'string'},
                        ]}
                    ]"></com-power-form>
                </div>
                <div v-if="item.key == 2">
                    <com-power-form :form-data="[
                        {label:'微信支付商户号',type:'text',value:'',key:'wx_mini_mchid',dataType:'string'},
                        {label:'微信支付密钥',type:'hide-text',value:'',key:'wx_mini_paysecert',dataType:'string'},
                        {label:'微信支付证书CERT',type:'upload-cert',value:'',key:'wx_mini_cert',dataType:'string'},
                        {label:'微信支付证书KEY',type:'upload-cert',value:'',key:'wx_mini_key',dataType:'string'},
                    ]"></com-power-form>
                </div>
                <div v-if="item.key == 3">
                    <com-power-form :form-data="[
                        {label:'服务商支付',type:'radio',value:'',key:'sp_open',dataType:'string',
                            options:[
                                {value:'1',label:'开启'},
                                {value:'0',label:'关闭'},
                            ]
                        },
                        {label:'服务商公众号ID',type:'text',value:'',key:'sp_appid',dataType:'string'},
                        {label:'服务商户号',type:'text',value:'',key:'sp_mchid',dataType:'string'},
                        {label:'子商户公众号ID',type:'text',value:'',key:'sub_appid',dataType:'string'},
                        {label:'子商户号',type:'text',value:'',key:'sub_mchid',dataType:'string'},
                    ]"></com-power-form>
                </div>
                <div v-if="item.key == 4">
                    <div class="tips">
                        <p>1、微信小程序订阅消息类目请选择 “宠物/农资” 类目。</p>
                    </div>
                    <a-form-item :wrapper-col="{offset:3}">
                        <a-space>
                            <a-button type="primary" @click="batchSetTemplate('wxapp')">一键设置模板消息</a-button>
                            <a-button type="primary" @click="createTemplateTestQrcode">生成测试二维码</a-button>
                        </a-space>
                    </a-form-item>
                    <com-power-form :form-data="[
                        {label:'充值成功通知',type:'text-btn',value:'',key:'mini_recharge_success',dataType:'string',
                            tipsImg:'admin/notice_wxapp/recharge.jpg'
                        },
                        {label:'下单成功通知',type:'text-btn',value:'',key:'mini_pay_success',dataType:'string',
                            tipsImg:'admin/notice_wxapp/pay_success.jpg'
                        },
                        {label:'订单取消通知',type:'text-btn',value:'',key:'mini_order_cancel',dataType:'string',
                            tipsImg:'admin/notice_wxapp/order_cancel.jpg'
                        },
                        {label:'发货提醒',type:'text-btn',value:'',key:'deliveryReminerId',dataType:'string',
                            tipsImg:'admin/notice_wxapp/delivery.jpg'
                        },
                        {label:'退款提醒',type:'text-btn',value:'',key:'refundReminderId',dataType:'string',
                            tipsImg:'admin/notice_wxapp/refund.jpg'
                        },
                        {label:'提现成功',type:'text-btn',value:'',key:'mini_withdraw_success',dataType:'string',
                            tipsImg:'admin/notice_wxapp/withdraw_success.jpg'
                        },
                        {label:'提现失败',type:'text-btn',value:'',key:'mini_withdraw_fail',dataType:'string',
                            tipsImg:'admin/notice_wxapp/withdraw_fail.jpg'
                        },
                        {label:'多商户审核结果通知',type:'text-btn',value:'',key:'shop_status_msg',dataType:'string',
                            tipsImg:'admin/notice_wxapp/store_check.jpg'
                        },
                        {label:'售后审核通过通知',type:'text-btn',value:'',key:'shop_sale_pass_notice',dataType:'string',
                            tipsImg:'admin/notice_wxapp/template-sale-pass-notice.jpg'
                        },
                        {label:'售后审核不通过通知',type:'text-btn',value:'',key:'shop_sale_fail_notice',dataType:'string',
                            tipsImg:'admin/notice_wxapp/template-sale-fail-notice.jpg'
                        },
                        {label:'工单状态变更提醒',type:'text-btn',value:'',key:'work_order_status_reminder',dataType:'string',
                        },
                    ]" @btnClick="showTemplateTest"></com-power-form>
                </div>
                <div v-if="item.key == 5">
                    <page-release></page-release>
                </div>
                <div v-if="item.key == 6">
                    <page-auth-release></page-auth-release>
                </div>
            </a-tab-pane>
        </a-tabs>

        <a-modal v-model:visible="show.wxapp_template"
            title="订阅消息测试二维码"
            :footer="null"
            @cancel="show.wxapp_template = false"
            width="500px">
            <div style="color: #999;font-size: 12px;text-align: center;">
				<div>温馨提示：请先保存好订阅消息之后在进行测试！</div>
				<a-image style="width: 300px;height: 300px;" :src="show.wxapp_template_test_url"></a-image>
			</div>
        </a-modal>
        <a-modal v-model:visible="show.template_test"
            title="模板消息示例"
            :footer="null"
            @cancel="show.template_test = false"
            width="500px">
            <template v-if="active == 'official'">
				<p>1.登录微信公众号平台，打开-模板消息-模板库</p>
				<p>2.搜索模板“{{show.template_title}}”并添加</p>
				<p>3.模板格式如图</p>
			</template>
			<template v-if="active == 'wxapp'">
				<p>1.进入小程序官方后台，找到订阅消息</p>
				<p>2.查找指定订阅消息并选用</p>
				<p>3.选择如下图的关键词，并按下图调好顺序，点击提交</p>
			</template>
			<div style="width: 100;height: 400px;overflow: hidden;overflow-y: auto;">
				<a-image
				   style="width: 100%;"
				   :src="$util.getStaticSrc(show.template_img)" />
			</div>
        </a-modal>
    </div>
</template>
<script>
import { reactive, ref, toRefs } from 'vue'
import set from "@/api/set.js"
import rh from '@/router/routerHandle.js'
import { useRoute } from 'vue-router'
import pageRelease from '@/components/layout/set/page-release.vue'
import pageAuthRelease from '@/components/layout/set/page-auth-release.vue'
import comPowerForm from '@/components/form/com-power-form.vue'
import saasSetModel from '@/api/saas/set';
import comCopy from '@/components/public/com-copy.vue'
export default {
    components:{
        pageRelease,
        pageAuthRelease,
        comPowerForm,
        comCopy
    },
    setup() {
        const active = ref("1")

        const _d = reactive({
            tabs:[],

            //微信小程序授权信息
            account:null,
            wx_account:null,	//微信公众号授权信息
            wx_mini_use_open:'0',

            show:{
                template_test:false,
                template_title:"",
                template_img:"",
                wxapp_template:false,
                wxapp_template_test_url:"",		//微信小程序测试二维码
            },

            saasWxppSet:{
                wx_mini_request_domain:"",
                wx_mini_uploadFile_domain:"",
                wx_mini_downloadFile_domain:"",
                domain_apply:""
            }
        })

        _d.tabs = rh.getCuurentTabs("miniappSet")
        const options = useRoute().query
        //是否存在授权码，存在重新设置授权信息
        if(options.auth_code){
            setOpenAuth(options.auth_code)
        }

        if( _d.tabs[0].key == 1 && _d.wx_mini_use_open == 1 ){
            getOpenAuth("min_program");
        }

        set.getProgramInfo().then(res=>{
			_d.wx_mini_use_open = res.config.wx_mini_use_open || '0'
			getOpenAuth('min_program');
            let ind = ""
            _d.tabs.forEach((item,index)=>{
                if( item.key == 6 && _d.wx_mini_use_open !=1 ){
                    ind = index
                }
            })
            if( ind ) _d.tabs.splice(ind,1)
		})

        set.handleAPPSite('get',res=>{
            if( res){
                _d.saasWxppSet.domain_apply = res.domain_apply
            }
        })

        saasSetModel.getSiteSet(Object.keys(_d.saasWxppSet),res=>{
			_d.saasWxppSet = res
		})

        function getAuthUrl(){
            set.getWxOpenAuthUrl().then(res=>{
                location.href = res
            })
        }

        function setOpenAuth(auth_code){
            set.setWxOpenAuth("min_program",auth_code).then(()=>{
                getOpenAuth("min_program")
            })
        }

        function getOpenAuth(auth_type){
            set.getWxOpenAuth(auth_type).then(res=>{
                _d.account = res
            })
        }

        function batchSetTemplate(){
            set.batchSetSubscribeMsg('wxapp').then(()=>{
                // getSetData()
            })
        }

         //展示模板消息示例
        function showTemplateTest(e){
            _d.show.template_title = e.title
            _d.show.template_img = e.img
            _d.show.template_test = true
        }

        function createTemplateTestQrcode(){
            set.getQrcode("/pages/public/template_test","wx_app").then(res=>{
                _d.show.wxapp_template = true
                _d.show.wxapp_template_test_url = res
            })
        }

        return {
            active,
            ...toRefs(_d),
            getAuthUrl,
            setOpenAuth,
            batchSetTemplate,
            showTemplateTest,
            createTemplateTestQrcode
        }
    },
}
</script>
<style lang="scss" scoped>

</style>
